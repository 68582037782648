export const faqs_sr = [
  {
    question: "Šta su to benefiti za zaposlene?",
    answer: "Benefiti su sve dodatne stvari koje poslodavac plaća u ime svojih zaposlenih\n" +
      "pored plate. Na primer, pogodnosti mogu uključivati popuste za članarinu\n" +
      "u teretani ili tretmane masaže, kompanijski automobil, privatnu penziju i\n" +
      "zdravstveno osiguranje ili pak poklon za decu. Fleksibilni benefiti su model\n" +
      "koji vam omogućava da samostalno birate svoje pakete benefita, u\n" +
      "definisanom budžetu koji dodeljuje poslodavac, kako biste zadovoljili\n" +
      "personalizovane potrebe i zahteve.",
  },
  {
    question: "Šta se dešava ako zaboravim lozinku?",
    answer: "Kliknite na „Zaboravili ste lozinku?“ na login stranici pa unesite svoju e-mejl\n" +
      "adresu registrovanu u HoB sistemu i kliknite na „Pošalji“. Link za kreiranje\n" +
      "nove lozinke biće vam poslat putem e-pošte. Proverite inbox (i spam) pa\n" +
      "kliknite na link, unesite novu lozinku i Potvrdite.",
  },
  {
    question: "Mogu li da se prijavim kao administrator kompanije?",
    answer: "Da biste se prijavili kao administrator, prvo se morate prijaviti kao običan\n" +
      "korisnik i označiti polje „Admin nalog“ koje se nalazi u zaglavlju, u padajućem\n" +
      "meniju. Zatim unesite svoju e-mejl adresu i lozinku (isto kao za korisnički nalog)\n" +
      "i kliknite na „Uloguj se“. Ako sačuvate kredencijale, svaki sledeći put\n" +
      "prebacivanje sa korisničkog na administratorski nalog će se automatski izvršiti."
  },
  {
    question: "Nedavno sam počeo da radim svoj novi posao. Kako mogu dobiti HoB korisnički nalog?",
    answer: "Ovaj proces traje uglavnom 1-3 nedelje nakon što ste počeli da radite na\n" +
      "novom radnom mestu. Vaši podaci za prijavljivanje na HoB platformu biće\n" +
      "Vam poslati odmah nakon kreiranja naloga.\n" +
      "Vaš korisnički nalog će biti kreiran čim dobijemo informacije o zaposlenju\n" +
      "od Vašeg poslodavca. Odmah po kreiranju naloga, pismo dobrodošlice će\n" +
      "Vam biti poslato redovnom e-poštom na adresu registrovanu u HoB\n" +
      "sistemu, sa linkom za kreiranje lozinke i aktivaciju naloga."
  },
  {
    question: "Gde mogu da vidim svoj ukupan i trenutni benefit budžet?",
    answer: "Informacije o Vašim benefit budžetima mogu se pronaći u zaglavlju\n" +
      "platforme, na progresnoj liniji, kao i u padajućem meniju → Opcija “Pogledaj\n" +
      "profil”. Na istom mestu ćete pronaći informacije o budžetu za određene\n" +
      "kategorije ako ih je poslodavac dodelio (npr. za učenje i razvoj), jer je\n" +
      "opciono i definisano politikom benefita poslodavca."
  },
  {
    question: "Zašto se sve dostupne kategorije beneficija ili pružaoci pogodnosti ne prikazuju na mom nalogu?",
    answer: "Svaki poslodavac kreira kastomizovano okruženje platforme prema svojoj\n" +
      "politici benefita, tako da se može dogoditi da su neke benefit\n" +
      "kategorije/potkategorije za Vas ograničene ili je zadržana kategorija ali\n" +
      "ograničen specifičan benefit provajder."
  },
  {
    question: "Kako kupiti benefit?",
    answer: "Postoje 3 osnovna načina kupovine benefita:\n" +
      "1. Benefiti označeni cenom mogu se kupiti online, preko web platforme/aplikacije.\n" +
      "Pod ovaj način spadaju i kupovine vaučera/poklon kartica.\n" +
      "Benefit kupujete tako što ga najpre dodate u korpu a zatim u pregledu korpe klikom\n" +
      "na ikonicu koja se nalazi u zaglavlju platforme pritisnete dugme “Na kasu” pa\n" +
      "Potvrdite kupovinu nakon što ste prvobitno čekirali da „razumete da će Vaš\n" +
      "poslodavac platiti naknadno poreze i doprinose na cenu benefita prema državnoj\n" +
      "legislativi“. Moguća je kupovina više benefita istovremeno.\n" +
      "Nakon dodavanja benefita u korpu možete uvećavati količinu što u slučaju poklon\n" +
      "vaučera znači biranje ukupnog iznosa na vaučeru (npr. Količina 2 za iznos benefita\n" +
      "500 = vrednost 1.000)\n" +
      "2. Benefiti označeni kao IN-STORE mogu se kupiti odlaskom u radnju provajdera\n" +
      "(označeno u opisu benefita na kojim lokacijama/radnjama je ovakva kupovina\n" +
      "moguća) i kupovinom „na licu mesta“ korišćenjem HoB digitalne kartice (korisničkog\n" +
      "ID-a) i HoB mobilne aplikacije.\n" +
      "3. Benefiti označeni sa INVOICE dogovaraju se u saradnji sa HoB timom ili\n" +
      "direktno sa partnerom, izdavanjem avansnog računa (predračuna) nakon čega vam\n" +
      "se automatski rezervišu sredstva iz budžeta za iznos koštanja benefita. Potrebno je\n" +
      "da predračun pošaljete kroz sekciju Moji zahtevi → Novi zahtev → Kupovina. Nakon\n" +
      "odobrenog zahteva od strane HoB tima kupovina će se pojaviti u sekciji Moje \n" +
      "kupovine gde pratite dalji status realizacije. Informacije naše firme za predračun\n" +
      "(naziv, PIB, adresa) nalazite u Kontakt delu padajućeg menija."
  },
  {
    question: "Šta podrazumeva IN-STORE kupovina?",
    answer: "Benefiti označeni kao IN-STORE mogu se kupiti odlaskom u radnju provajdera\n" +
      "(označeno u opisu benefita na kojim lokacijama/radnjama je ovakva kupovina\n" +
      "moguća) i kupovinom „na licu mesta“ korišćenjem HoB digitalne kartice\n" +
      "(korisničkog ID-a) i HoB mobilne aplikacije\n" +
      "* Vaš HoB korisnički ID možete pronaći na HoB digitalnoj kartici ili sekciji\n" +
      "Pogledaj profil. Prilikom kupovine direktno u objektu partnera potrebno je\n" +
      "najpre da:\n" +
      "a) Izdiktirate/pokažete svoj HoB korisnički ID\n" +
      "b) Kada dobijete verifikacioni 2wa kod u delu notifikacija na HoB mobilnoj\n" +
      "aplikaciji isti izdiktirate partneru kako bi se kupovina mogla uspešno izvršiti.\n" +
      "c) Nakon uspešne kupovine u notifikacijama stiže poruka sa obaveštenjem o\n" +
      "izvršenoj kupovini a budžet (trenutno stanje) na nalogu se umanjuje za\n" +
      "vrednost kupljenog proizvoda/usluge. \n" +
      "d) Listu kupovina i statuse realizacije možete pratiti u sekciji Moje kupovine."
  },
  {
    question: "Kako mogu pratiti status transakcija?",
    answer: "Ako ste izvršili transakciju, možete proveriti status preko naše mobilne\n" +
      "aplikacije ili web platforme! Jednostavno idite na padajući meni, odeljak\n" +
      "Moje transakcije gde ćete uživo dobiti uvid u listu izvršenih transakcija\n" +
      "(kupljenih benefita) sa svim potrebnim informacijama i praćenjem statusa\n" +
      "kupovine (“u procesu” ili “završeno”) sa mogućim izvozom pdf potvrde o\n" +
      "kupovini. Takođe postoji mogućnost otkazivanja/storniranja transakcije\n" +
      "pre njene realizacije ili u slučaju reklamacije kupljenog artikla.\n" +
      "Da biste filtrirali transakcije, kliknite na polja u gornjem uglu za filtriranje po\n" +
      "datumu, benefit provajderu, statusu ili vrsti transakcije (refundacije se\n" +
      "nalaze u odeljku Moji zahtevi, a transakcije u odeljku Moje transakcije).",
  },
  {
    question: "Gde mogu da pronađem aplikativnu formu za prijavu tim bildinga?",
    answer: "Šablon Team building prijave se može naći u padajućem meniju u odeljku\n" +
      "“Moja download zona” → Fajlovi i šabloni . Popunjenu formu potom šaljete\n" +
      "putem e-mejla na adresu teambuilding@hob.rs"
  },
  {
    question: "Da li je moguće izvršiti refundaciju? Kako predajem zahtev?",
    answer: "Moguće je, ali samo ukoliko Vam poslodavac dozvoli ovu opciju jer je\n" +
      "poslodavac taj koji u potpunosti kontroliše i administrira ovaj segment na\n" +
      "platformi kroz administratorki kompanijski nalog. Za slanje zahteva za\n" +
      "refundaciju idite u padajućem meniju na odeljak Moji zahtevi, izaberite tip\n" +
      "Refundacija i popunite pažljivo formular unoseći sve tražene informacije.\n" +
      "Dodajte račun iz svoje foto galerije i pošaljite. Molimo Vas da proverite da li\n" +
      "je na računu jasno vidljivo šta je kupljeno i cena. Ako je traženi iznos veći od\n" +
      "ukupnog iznosa prikazanog na priloženom računu, trošak će biti odbijen.\n" +
      "Kada primimo Vaš zahtev, on će biti prikazan sa statusom„U pregledu“. To\n" +
      "samo znači da je u redu da će ga Vaš poslodavac proveriti da li ispunjava\n" +
      "uslove a zatim odobriti ili odbiti."
  },
  {
    question: "Koliko dugo traje process refundacije troška benefita na platu?",
    answer: "Sve preglede zahteva za refundacijom vrši direktno poslodavac putem svog\n" +
      "administratorskog naloga a ne HoB tim. Obično proces obrade zahteva traje\n" +
      "do 10 radnih dana od podnošenja.\n" +
      "Ukoliko je zahtev odobren, poslodavac šalje uplatu iznosa na Vašu sledeću\n" +
      "isplatu mesečne zarade i iznos se pojavljuje na platnom listiću.\n" +
      "Da biste proverili status refundacije, idite na svoje nedavne zahteve i\n" +
      "pregledajte listu ili filtrirajte po tipu zahteva da biste videli samo refundacije\n" +
      "i proverili njihov status. Svi „prihvaćeni“ zahtevi će biti refundirani, a\n" +
      "„odbijeni“ neće biti refundirani ali će se budžet vratiti na nalog i dobićete\n" +
      "povratne informacije sa komentarima zašto je Vaš zahtev odbijen."
  },
  {
    question: "Šta predstavlja sekcija zvana Osnovni benefiti?",
    answer: "U ovoj sekciji možete pronaći sve informacije o fiksnim (osnovnim)\n" +
      "benefitima koje obezbeđuje Vaš poslodavac. Ovi benefiti ne mogu biti\n" +
      "izabrani, otkazani ili promenjeni jer su oni unapred predefinisani za Vas\n" +
      "(npr. bolovanje bez doznaka, kantina, kompanijski automobil, poklon za\n" +
      "rođenje deteta, rad od kuće itd..).\n" +
      "Klikom na karticu nekog osnovnog benefita dobijate uvid u detaljne\n" +
      "informacije kroz opis, instrukcije i ograničenja, opcionalno prilog\n" +
      "(dokument) na jednom mestu."
  },
  {
    question: "Kako i gde mogu koristiti specijalne ponude i popuste?",
    answer: "U sekciji specijalne ponude mogu se pronaći specijalni popusti i ponude kod\n" +
      "partnera na kompletan ili deo asortimana robe/usluga. Detaljnije\n" +
      "informacije o definisanom popustu možete pronaći na kartici specijalne\n" +
      "ponude konkretnog benefit provajdera (partnera) kroz opis, instrukcije,\n" +
      "ograničenja i prilog.\n" +
      "Specijalni popusti uz House of Benefits digitalnu karticu važe i za kupovinu\n" +
      "iz sopstvenih novčanih sredstava, nevezano za budžet koji vam je \n" +
      "poslodavac dodelio na platformi a koji koristite u odeljku fleksibilnih\n" +
      "benefita."
  },
  {
    question: "Mogu li da predložim ili kupim benefite koji nisu ulistiani na HoB? I kako?",
    answer: "Uvek možete predložiti novu pogodnost ili provajdera pogodnosti klikom u\n" +
      "padajućem meniju na opciju Predloži benefit ili pisanjem e-mejla na\n" +
      "support@hob.rsi a mi ćemo se potruditi da taj novi benefit dodamo na\n" +
      "predefinisanu listu HoB-a. Stopa uspešnosti je viša od 90% i neko prosečno\n" +
      "vreme ulistavanja novog vendora je 15-30 dana."
  },
  {
    question: "Da li mogu da kombinujem FLEX i KATEGORIJSKI budžet za kupovinu benefita?",
    answer: "Da, možete koristiti svoj Flex benefit budžet u slučaju da nemate dovoljno\n" +
      "novca da pokrijete ceo iznos iz kategorijskog budžeta i to će sistem\n" +
      "automatski uraditi za Vas. Međutim, obrnuto nije moguće, ne možete\n" +
      "koristiti svoj kategorijski budžet kao Flex, tj. možete ga koristiti samo za\n" +
      "kupovinu benefita iz te specifične kategorije."
  },
  {
    question: "Šta znači obračun poreza i doprinosa u pregledu Korpe prilikom kupovine benefita?",
    answer: "Oporezivost se definiše na nivou kategorije. Ako Vaša kompanija nudi\n" +
      "program benefita koji je oporeziv, svaka potrošnja u okviru tog programa\n" +
      "biće oporeziva, bez obzira na to koju stavku kupite.\n" +
      "Ako Vaša kompanija nudi neoporezivi program, sve što kupite neće se\n" +
      "smatrati oporezivim i oslobođeno je obračuna doprinosa.\n" +
      "Ukoliko Vas zanima koje stavke bi bile oporezive/neoporezive, možete\n" +
      "pronaći više detalja o ovome u kompanijskoj politici benefita za zaposlene.\n" +
      "Poreska pravila postavljaju relevantne lokalne samouprave i razlikuju se od\n" +
      "zemlje do zemlje. U mnogim zemljama wellness, sport, mentalno zdravlje,\n" +
      "topli obrok, javni prevoz, osiguranje, donacije, učenje i razvoj su neoporezivi,\n" +
      "ali programi putovanja, mode i kozmetičkih salona, goriva, tehnologije,\n" +
      "kulture i zabave, poklona, stanovanja itd. se oporezuju jer su osmišljeni za\n" +
      "potrošnju nečega što nije direktno povezano sa obavljanjem Vašeg posla.\n" +
      "Ako niste sigurni da li se neki benefiti oporezuju, uvek možete da nam se\n" +
      "obratite i neko iz našeg tima za podršku će Vam dati odgovor. Takođe, u\n" +
      "trenutku kupovine benefita u korpi možete da vidite da li se obračunava\n" +
      "porez i doprinosi ili ne, sa procenjenim iznosom poreza i doprinosa koji će\n" +
      "poslodavac dodatno platiti državi i koji će biti prikazan na Vašoj platnoj listi."
  },
  {
    question: "Dodao sam benefit u Korpu, ali transakcija nije realizovana. Zašto?",
    answer: "Ukoliko samo dodate benefit u Korpu i ne Potvrdite kupovinu, pogodnost\n" +
      "neće biti realizovana i Vaš budžet neće biti umanjen. Dakle, nakon\n" +
      "dodavanja benefita u Korpu morate da završite uspešno kupovinu kako bi\n" +
      "HoB “back office” tim realizovao tu transakciju a Vi dobili svoj benefit."
  },
  {
    question: "Gde mogu pronaći više informacije o nekom konkretnom benefit?",
    answer: "Svaka benefit kartica sadrži informacije kroz 5 različitih odeljaka: o\n" +
      "detaljima, opisu, instrukcijama, ograničenjima i promo materijalima\n" +
      "(prilozima) kao i slikovit prikaz sa fotografijama.\n" +
      "Klikom na ikonicu pored cene možete dobiti više informacija o dodatnom\n" +
      "poreskom tretmanu benefita koji će platiti Vaš poslodavac. Zatim, prilikom\n" +
      "kupovine benefita aplikacija/platforma će prikazati i aproksimativni trošak\n" +
      "poreza i doprinosa koji će dodatno biti obračunati i plaćeni od strane Vašeg\n" +
      "poslodavca na regularnu cenu benefita (nezavisno od HoB budžeta)."
  },
  {
    question: "Koja je razlika između “pretplate” i “kupovine” benefita?",
    answer: "Pretplata podrazumeva kupovinu na duži period pri čemu se budžet\n" +
      "automatski skida svakog meseca u iznosu troška te mesečne pretplate pri\n" +
      "čemu se pretplata produžava po automatizmu i za novi mesec SVE DOK NE\n" +
      "URADITE ODJAVU. Kupovina benefita dodavanjem u korpu ili in-store je\n" +
      "jednokratna i neponavljajuća pri čemu se ukupan trošak u iznosu benefita\n" +
      "skida odmah nakon uspešne kupovine."
  },
  {
    question: "Gde mogu da pronađem informacije do kada je moj benefit budžet dostupan ili do kada se može koristiti?",
    answer: "Obzirom da rok definiše Vaš poslodavac i da nije striktno uvek isti, uvek je\n" +
      "prikazan u formi inicijalnog obaveštenja koje dobijate na e-mejl (od HoB ili\n" +
      "internog HR tima) pri aktivaciji naloga ili dodeli budžeta."
  },
  {
    question: "Šta je HoB e-vaučer?",
    answer: "E-vaučer predstavlja digitalnu poklon karticu (QR ili barkod). Pronađite i\n" +
      "poručite željeni e-vaučer na HoB App ili na platform.hob.rs i u zavisnosti od\n" +
      "uslova koje propisuje Partner, možete da ga iskoristite za online ili kupovinu\n" +
      "u radnji. Svaki e-vaučer ima svoj rok važenja definisan od strane partnera a\n" +
      "koji je uglavnom između 6 meseci i 3 godine, najčešće godinu dana."
  },
  {
    question: "Kupio sam e-vaučer, kako i kada ću ga dobiti?",
    answer: "E-vaučer dobijate u elektronskom obliku na svoj mejl registrovan u HoB\n" +
      "sistemu (onaj sa kojim radite login) ili direktno na svoj HoB nalog u deo Moje\n" +
      "sanduče, u formi poruke sa notifikacijom o prijemu. Rok za dostavljanje evaučera naznačen je u opisu svakog benefita a najčešće je 5-10 radnih dana."
  },
  {
    question: "Kako mogu lako pronaći benefit za koji sam zainteresovan?",
    answer: "Pretraga benefita je moguća korišćenjem leve trake sa kategorijama (npr.\n" +
      "zdravlje, učenje, tehnika, putovanja, prevoz, sport i rekreacija... itd.), i/ili po\n" +
      "benefit provajderu (selektovanjem željenog iz liste ponuđenih benefit\n" +
      "provajdera). moguć je i filter po lokaciji ili načinu kupovine (online, e-vaučer,\n" +
      "direktno u radnji ili predračun). Moguća je i detaljnija pretraga po\n" +
      "potkategorijama u okviru neke kategorije (npr. medicinske ustanove u\n" +
      "okviru kategorije zdravlje i slično), ili po nazivu benefita (unošenjem teksta\n" +
      "naziva benefita u polje za pretragu)."
  },
  {
    question: "Kako da preuzmem mobilnu aplikaciju House of Benefits?",
    answer: "Bez obzira da li imate Android ili iOS uređaj, HoB aplikacija je dostupna za\n" +
      "preuzimanje na Vašem trenutnom smart mobilnom uređaju! Da biste\n" +
      "preuzeli HoB aplikaciju na Android uređaj, idite u Google Play Store, a za iOS\n" +
      "uređaj u App Store i pretražite po nazivu House of Benefits. Instalirajte\n" +
      "aplikaciju.\n" +
      "Da biste optimizovali svoje korisničko iskustvo, treba da budete sigurni da\n" +
      "uvek koristite najnoviju verziju aplikacije."
  },
  {
    question: "Kako kontaktirati HoB podršku?",
    answer: "Zdravo! Rado ćemo odgovoriti na sva Vaša pitanja, pružiti podršku i rešiti\n" +
      "sve što Vam je potrebno. Najbolji način da dođete do tima za podršku je\n" +
      "putem e-mejla support@hob.rs ili pozivom kontakt centra telefonom na bilo\n" +
      "koji od brojeva navedenih u odeljku Kontakt.\n" +
      "Kada pošaljete svoj e-mejl, javićemo Vam se u maksimalnom roku od 1-2\n" +
      "radna dana."
  },
  {
    question: "Šta ukoliko nemam dovoljno budžeta da pokrijem ceo iznos koštanja benefita?",
    answer: "U slučaju nedovoljnog budžeta za kupovinu benefita moguća je doplata\n" +
      "razlike partneru iz sopstvenih sredstava (keš, kartica…) i ovu informaciju\n" +
      "najčešće pronalazite unutar benefit kartice u sekciji instrukcije i/ili\n" +
      "ograničenja.\n" +
      "Takođe, možete koristiti svoj Flex benefit budžet u slučaju da nemate\n" +
      "dovoljno novca da pokrijete ceo iznos iz kategorijskog budžeta i to će sistem\n" +
      "automatski uraditi za Vas. Međutim, obrnuto nije moguće, ne možete\n" +
      "koristiti svoj kategorijski budžet kao Flex, tj. možete ga koristiti samo za\n" +
      "kupovinu benefita iz te specifične kategorije."
  }
]

export const faqs_en = [
  {
    question: "What are employee benefits?",
    answer: "Employee benefits are all the additional things an employer pays for on behalf of its employees in addition to salary. For example, benefits may include discounts for gym memberships or massage treatments, a personal car, pension and insurances or child gift. Flexible benefits are model that allows you to independently choose your benefit packages, within the benefit budget allocated by the employer, in order to meet personalized needs and requirements.",
  },
  {
    question: "What happens if I've forgotten my password?",
    answer: "Click on “Forgot your password?” on the log in page, enter your email\n" +
    "registered in HoB system and click “Send email”. A link to create your new\n" +
    "password will be sent to you via email. Check your Inbox (and Junk), Click on\n" +
    "the link, enter your new password and click Confirm.",
  },
  {
    question: "Can I log in as an company administrator?",
    answer: "To log in as an administrator, first you need to log in as normal user and\n" +
    "check the “Switch to Admin” box located in header, in drop down menu.\n" +
    "Next, enter your email and password (same as for user account) and click \n" +
    "“Sign in”. If you save the credentials every next switch will be automatically\n" +
    "done."
  },
  {
    question: "I have recently started at my new job. How can I get a HoB user account?",
    answer: "This process takes approximately 1-3 weeks after you have started at your\n" +
    "new workplace. Your log in details will be sent to you immediately after the\n" +
    "account has been created.\n" +
    "Your user account will be created as soon as we receive information about\n" +
    "your employment from your employer. Once an account has been created,\n" +
    "a welcome letter will be sent to you by regular email with the link to create\n" +
    "your password and activate the account."
  },
  {
    question: "Where I can see my total and current benefit budget?",
    answer: "Information about your benefit budget(s) can be found in header of the\n" +
    "platform as progress line, as well as in drop down menu → View Profile\n" +
    "option. In the same place you will find information on specific category\n" +
    "budget if you have been assigned to (e.g. for Learning & Growth), this is\n" +
    "optional and defined by the employer benefit policy."
  },
  {
    question: "Why aren't all the available benefit categories or benefit providers showing up on my account?",
    answer: "Each employer creates a customized platform environment according to its\n" +
    "benefit policy, so it may happen that some benefit categories/subcategories\n" +
    "are limited for you or a category is retained but a specific benefit provider\n" +
    "is restricted."
  },
  {
    question: "How to buy a benefit?",
    answer: "There are 3 ways or methods of buying the benefit.\n" +
    "1. Benefits marked with the price can be bought online, through the web\n" +
    "platform/mob app. You buy benefit by adding it to the cart first, then\n" +
    "entering the cart which is located in the header part and then press the\n" +
    "button “Confirm the purchase” after you have checked that „understand\n" +
    "that my employer will pay additional payroll taxes and contributions on the\n" +
    "benefit price according to fiscal legislation “. It is possible to buy multiple\n" +
    "benefits at the same time.\n" +
    "2. Benefits marked as IN-STORE can be bought directly in store of the\n" +
    "partner using the HoB digital card (user ID) and HoB mobile app.\n" +
    "3. Benefits marked with INVOICE are arranged in cooperation with the\n" +
    "HoB team or directly with the partner, by issuing an advance invoice (proforma invoice) after which your funds will be automatically deducted from\n" +
    "the budget for the amount of the benefit cost.\n" +
    "You need to send a pro-forma invoice through the section My Requests →\n" +
    "New Request → Transaction. After the request has been approved by the\n" +
    "HoB team, the purchase will appear in My Transactions section where you\n" +
    "can follow the further status of the realization."
  },
  {
    question: "What does it mean IN-STORE shopping?",
    answer: "Benefits marked as IN-STORE can be bought directly in store of the partner\n" +
    "using the HoB digital card (user ID) and HoB mobile app. HoB user ID can\n" +
    "be found on digital HoB card or in Profile menu. When purchasing directly\n" +
    "in-store, it is necessary to:\n" +
    "a) Tell/show your HoB user id and then\n" +
    "b) When you receive a 2wa code in the notification section of the HoB\n" +
    "mobile application, you tell/show it to the partner so that the purchase\n" +
    "can be successfully finalized.\n" +
    "c) After a successful purchase, a message arrives at your e-mail address\n" +
    "with a notification of the purchase made and the budget (current\n" +
    "balance) on the account is reduced by the value of the purchased\n" +
    "product / service.\n" +
    "d) You can find all your purchases in my transactions section. "
  },
  {
    question: "How to follow status of my transactions?",
    answer: "If you did a transaction, you can check the status via our app or web\n" +
    "platform! Simply go to drop down menu, section My Transactions where you\n" +
    "will find live insight into the list of executed transactions (purchased\n" +
    "benefits) with all the necessary info and monitoring of the status of the\n" +
    "purchase (in-process or completed with possible export of pdf transaction\n" +
    "confirmation). There is also possibility to cancel the transaction before it’s\n" +
    "realization or in case of article complaint.\n" +
    "To filter transactions, click the icons in the upper corner to filter by Date,\n" +
    "Benefit, Status, or Transaction Type (Reimbursement is placed in My\n" +
    "Requests section and transaction in My Transactions section).",
  },
  {
    question: "Where I can find application for the team building?",
    answer: "Team building application template can be found in drop down menu in\n" +
    "section My Download Zone. Completed for you are sending via e-mail to\n" +
    "teambuilding@hob.rs"
  },
  {
    question: "Is it possible to make a reimbursement? How do I submit?",
    answer: "It is possible but only if your employer allows this option for you. To submit\n" +
    "go in drop down menu to the section My Requests and complete the\n" +
    "reimbursement form carefully filling in the information requested.\n" +
    "Add receipt from your photo gallery. Please make sure the receipt clearly\n" +
    "show what was purchased and the price. If the amount requested is higher\n" +
    "than the total amount shown on the provided receipt, the expense will be\n" +
    "denied.\n" +
    "Once we receive your reimbursement, it’ll show as “In review”. That just\n" +
    "means it’s in line to be checked for eligibility by your employer and approved\n" +
    "or declined. "
  },
  {
    question: "How long will it take to receive my reimbursement?",
    answer: "All reviews of the reimbursements are done directly by the employer\n" +
    "through the company admin account and not the HoB team. Usually it takes\n" +
    "within 10 business days of submission.\n" +
    "If approved, employer send payments on your next salary payroll/payment.\n" +
    "To check on the status of your reimbursement, navigate to your recent\n" +
    "requests and review the list or filter to view only reimbursements to check\n" +
    "their status. Any \"Approved\" reimbursements will have been refunded and\n" +
    "any “Canceled” will have not been refunded and you will get your budget\n" +
    "back to the account as well as feedback with comment why your request is\n" +
    "denied."
  },
  {
    question: "How and where I can use supplier special offers and discounts?",
    answer: "In this section you can find all special offers and discounts on standard\n" +
    "prices provided for you by our partners. More detailed information about\n" +
    "the defined discount can be found for the specific benefit provider (partner)\n" +
    "through description, instructions, limitations and attachment tabs.\n" +
    "Special discounts, with the digital HoB membership card, also apply to\n" +
    "purchases from your own funds, unrelated to your budget allocated by the\n" +
    "employer on the platform for flexible benefits section."
  },
  {
    question: "Can I suggest or buy the benefits which are not listed on HoB? And how?",
    answer: "You can always suggest new benefit or benefit provider clicking in drop\n" +
    "down menu on option Benefit Suggestion or writing an e-mail to\n" +
    "support@hob.rs and we will do our best to add that new benefit to the\n" +
    "predefined list. Succession rate is higher than 90% and some average time\n" +
    "of listing new benefit provider is 15-30 days."
  },
  {
    question: "Can I combine FLEX and CATEGORY budget for buying the benefit?",
    answer: "Yes, you can use your Flex budget in case you do not have enough money\n" +
    "to cover expanse from your Category budget and this will be done\n" +
    "automatically by the system. But vice versa is not possible, you cannot use\n" +
    "your specific Category budget as Flex (you can use it only for that specific\n" +
    "category benefit)."
  },
  {
    question: "What does it mean taxes and contributions calculation in Cart when buying the benefit?",
    answer: "Taxability is decided at a program level. If your company offers a benefit\n" +
    "program that is taxable, any spending under that program will be taxable,\n" +
    "no matter what item you purchase.\n" +
    "If your company offers a non-taxable program, anything you buy will not be\n" +
    "considered taxable.\n" +
    "If you are curious about what items would be taxable/non-taxable, you can\n" +
    "refer to your benefit policy.\n" +
    "Tax rules are set by relevant local governments and vary from country to\n" +
    "country. In many countries’ wellness, fitness, mental health, meal, public\n" +
    "transport, insurance, donation, learning&development is non-taxable but\n" +
    "travel, fashion, fuel, technology, culture&creativity, gifts, housing etc.\n" +
    "programs are taxable as they are designed for spending not directly\n" +
    "associated with completing your job.\n" +
    "If you're not sure if your benefits are taxable, you can always reach out to\n" +
    "us and someone from our support team will be able to confirm. Also, in the\n" +
    "moment of buying the benefit in the Cart you are able to see whether it was\n" +
    "considered taxable or not with an estimated taxes & contribution amount\n" +
    "that will be additionally paid by your employer and shown in your pay slip."
  },
  {
    question: "I’ve added benefit in Cart but transaction is not realized. Why?",
    answer: "If you just add benefit in the Cart and do not confirm your purchase, benefit\n" +
    "will not be realized and your budget will not be deducted. So, after adding\n" +
    "the benefit in the Cart you have to finalize your purchase in order to HoB\n" +
    "back office complete the request and you get your benefit.s"
  },
  {
    question: "Where I can find more information about concrete benefit?",
    answer: "Each and every benefit card contains 5 different sections: details,\n" +
    "description, instructions, limitations and attachments as well as related\n" +
    "photos.\n" +
    "By clicking on the icon next to the price you can get more info about the\n" +
    "additional taxation of the benefit that will be additionaly paid by your\n" +
    "employer. Then, when purchasing benefits, the application / platform will\n" +
    "also display the approximate cost of taxes and contributions that will \n" +
    "additionally be calculated and paid by your employer at the regular price\n" +
    "of the benefit (separated from the HoB budget)."
  },
  {
    question: "What is the difference between “Subscribe” and “Buying” the benefit.",
    answer: "A subscription implies a purchase for a longer period, whereby the budget\n" +
    "is automatically deducted every month in the amount of the cost of that\n" +
    "monthly subscription, whereby the subscription is automatically extended\n" +
    "for the new month UNTIL YOU UNSUBSCRIBE. The purchase of benefits by\n" +
    "adding them to the cart or in-store or invoice buying is one-time and nonrecurring, so the total cost in the amount of benefits is deducted\n" +
    "immediately after a successful purchase."
  },
  {
    question: "Where I can find information until when my benefit budget is available or can be used?",
    answer: "Considering fact that the deadline is defined by your employer and is not\n" +
    "strictly always the same, this information is always sent/shown in the form\n" +
    "of an initial notification that you receive by e-mail (from the HoB or internal\n" +
    "HR team) when the account is activated or the budget is allocated."
  },
  {
    question: "What is HoB e-voucher?",
    answer: "E-voucher is a digital gift card (QR or barcode). Find and order the desired\n" +
    "e-voucher on the HoB App or web platform and, depending on the\n" +
    "conditions prescribed by the Partner, you can use it for online and/or instore purchases. Each e-voucher has its own validity period defined directly\n" +
    "by Partner, which is usually between 6 months and 3 years, typically one\n" +
    "year."
  },
  {
    question: "I have bought e-voucher. How I will receive it and when?",
    answer: "You will receive the e-voucher in electronic form to your email registered in\n" +
    "the HoB system (the one with which you log in) or directly to your HoB\n" +
    "account in the My Inbox section, in the form of a message with notification.\n" +
    "The deadline for delivering the e-voucher is indicated in the description of\n" +
    "each benefit and is usually 5-10 business days."
  },
  {
    question: "How I can make an easy search of benefits I am interested in?",
    answer: "Benefit search is possible using the left lane with benefit categories (e.g.\n" +
    "Medical, Learning, Technology, Transport, Sport and Recreation...). And/or\n" +
    "filtering by benefit providers (selecting some from the list). There is also\n" +
    "filter by location or method of purchase (online, e-voucher, in-store or\n" +
    "invoice). A more detailed search by subcategories within a category (e.g.\n" +
    "Ophthalmology within the category of medical), or by the name of the\n" +
    "benefit (by entering the text of the name of the benefit in search box) is also\n" +
    "possible."
  },
  {
    question: "How do I download the House of Benefits mob app?",
    answer: "Whether you have an Android or iOS device, the HoB app is available for\n" +
    "download on your current smart mobile device! To download the HoB app\n" +
    "onto an Android device, head to the Google Play Store and for iOS device to\n" +
    "the App Store and searching for House of Benefits. Click Install.\n" +
    "To optimize your experience, you’ll want to make sure you are using the\n" +
    "most up-to-date version of the app."
  },
  {
    question: "How to contact HoB Support?",
    answer: "Hi there! We’re happy to help answer your questions and troubleshoot\n" +
    "anything that you need. The best way to reach the support team is by e-mail\n" +
    "support@hob.rs or calling live/phone support to any of the phone numbers\n" +
    "listed in section Contact.\n" +
    "Once you send your e-mail, you will hear back from us within 1-2 business\n" +
    "days."
  },
  {
    question: "What if I do not have enough budget to cover the full amount of benefit price?",
    answer: "In case of insufficient budget for the purchase of benefits, it is possible to\n" +
    "pay the difference to the partner from your own funds (cash or card) and \n" +
    "information about this possibility can be found within the benefit card in\n" +
    "the instructions and / or limitations section).\n" +
    "Also, for buying from specific Category budget you can use your Flex budget\n" +
    "in case you do not have enough money to cover expanse from your\n" +
    "Category budget and this will be done automatically by the system. But vice\n" +
    "versa is not possible, you cannot use your specific Category budget as Flex\n" +
    "(you can use it only for that specific category benefit)."
  },
]