<template>
  <div class="tracking-wide mt-6">
    <span class="font-bold">PDF</span> {{ loc.tutorial }}:
    <a v-if="$store.state.auth.user.language === 'SR'" class="text-gray-700 hover:text-base-500 underline" target="_blank" href="https://hob.fra1.cdn.digitaloceanspaces.com/UPUTSTVO%20ZA%20KORI%C5%A0%C4%86ENJE%20APLIKACIJE.pdf">
      {{loc.download_here}}
    </a>
    <a v-if="$store.state.auth.user.language === 'EN'" class="text-gray-700 hover:text-base-500 underline" target="_blank" href="https://hob.fra1.cdn.digitaloceanspaces.com/UPUTSTVO%20ZA%20KORI%C5%A0%C4%86ENJE%20APLIKACIJE%20eng.pdf">
      {{loc.download_here}}
    </a>
  </div>
  <div class="mt-5">
    <p class="font-bold">Video <span class="font-normal">{{ loc.tutorial }}:</span></p>
    <div class="video-container mt-3">
      <video
          ref="videoPlayer"
          controls
          class="rounded-xl overflow-hidden w-full"
          src="https://hob.fra1.cdn.digitaloceanspaces.com/web%20platform%20video.mov"
      />
      <button @click="playVideo" v-show="isPaused" class="play-button">▶</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "Tutorial",
  data() {
    return {
      isPaused: true,
    };
  },
  methods: {
    playVideo() {
      const video = this.$refs.videoPlayer;
      if (video.paused) {
        video.play();
        this.isPaused = false;
      } else {
        video.pause();
        this.isPaused = true;
      }
    },
  },
  mounted() {
    const video = this.$refs.videoPlayer;
    video.addEventListener("pause", () => {
      this.isPaused = true;
    });
    video.addEventListener("play", () => {
      this.isPaused = false;
    });
  },
};
</script>

<style scoped>
.video-container {
  position: relative;
  width: 100%;
}

.play-button {
  width: 120px;
  height: 120px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 50px;
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  opacity: 0.7;
  transition: opacity 0.3s ease;
}

.play-button:hover {
  opacity: 1;
}

video {
  width: 100%;
  height: auto;
}
</style>
