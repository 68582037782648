<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div class="bg-gray-50 mt-6 py-8 rounded-xl">
    <div class="mx-auto px-2 md:px-4 lg:px-6">
      <div class="mx-auto divide-y-2 divide-gray-200">
        <h2 class="text-xl font-bold text-gray-900">
          {{ loc.frequently_asked_questions }}
        </h2>


        <dl class="mt-6 space-y-3 divide-y divide-gray-200">
          <Disclosure as="div" v-for="faq in faqs" :key="faq.question" class="pt-6 px-2 md:px-3 lg:px-6" v-slot="{ open }">
            <dt class="">
              <DisclosureButton class="text-left w-full flex justify-between items-start text-gray-400">
                <span class="font-medium text-gray-900">
                  {{ faq.question }}
                </span>
                <span class="ml-6 h-7 flex items-center">
                  <ChevronDownIcon :class="[open ? '-rotate-180' : 'rotate-0', 'h-6 w-6 transform']" aria-hidden="true" />
                </span>
              </DisclosureButton>
            </dt>
            <DisclosurePanel as="dd" class="mt-2 pr-2 md:pr-4 lg:pr-10">
              <p class="text-base text-gray-500 px-2">
                {{ faq.answer }}
              </p>
            </DisclosurePanel>
          </Disclosure>
        </dl>
      </div>
    </div>
    <div class="flex justify-end p-7 mt-10">
      <div class="w-full md:w-44">
        <p class="font-medium tracking-tight">{{ loc.you_have_a_question }}</p>
        <button @click="$router.push('/contact')" class="w-full md:w-40 rounded-md bg-base-600 text-white md:px-1 xl:px-4 h-10 flex items-center justify-center gap-x-2 hover:bg-base-500 transition duration-200 ease-in-out">
          <font-awesome-icon icon="fa-solid fa-comments" />
          <span class="text-sm">{{ loc.contact_us }}</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'
import { ChevronDownIcon } from '@heroicons/vue/outline'
import { faqs_sr, faqs_en } from '@/views/tutorial-and-faq/faqs';
import {mapGetters} from 'vuex';


export default {
  components: {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    ChevronDownIcon,
  },
  computed: {
    ...mapGetters('auth', ['getLanguageHelper']),
    faqs() {
      return this.$store.state.auth.user.language === 'SR' ? faqs_sr : faqs_en
    }
  },
}
</script>