<template>
  <div class="my-24">
    <div class="rounded-xl overflow-hidden xl:h-80 border">
      <img src="../../assets/images/faq-pic.png" alt="banner" class="rounded-xl">
    </div>
    <div :class="`bg-white p-1 rounded-xl md:w-64 lg:mx-0 mt-6`">
      <div :class="`relative mx-auto flex items-center justify-between font-medium bg-white rounded-xl w-full cursor-pointer select-none overflow-hidden`">
        <div :class="`absolute left-0 top-0 transform ${tabIndex === 1 ? 'translate-x-0' : ''} ${tabIndex === 2 ? 'translate-x-100%' : ''} top-0 bg-base-600 w-1/2 h-8 p-1 rounded-xl animation duration-200 ease-in-out`"></div>
        <div @click="tabIndex = 1" :class="`${tabIndex === 1 ? 'text-white' : ''} w-1/2 flex items-center justify-center rounded-lg h-8 animation duration-300 ease-in-out`">
          <p class="relative">{{ loc.tutorial }}</p>
        </div>
        <div @click="tabIndex = 2" :class="`${tabIndex === 2 ? 'text-white' : ''} w-1/2 flex items-center justify-center rounded-lg h-8 animation duration-300 ease-in-out`">
          <p class="relative">FAQ</p>
        </div>
      </div>
    </div>
    <Tutorial v-if="tabIndex === 1" />
    <Faq v-if="tabIndex === 2" />
  </div>
</template>

<script>
import Faq from './Faq.vue';
import Tutorial from './Tutorial.vue';

export default {
  name: "index",
  data() {
    return {
      tabIndex: 1,
    }
  },
  components: {Faq, Tutorial},
  methods: {

  },
}
</script>


<style scoped>

</style>